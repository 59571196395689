<template>
  <base-layout>
    <section class="prizes">
      <h1>Призы</h1>
      <div class="prizes-block-1">
        <h2>Копи баллы и обменивай их на гарантированный<br>
          супермерч с AR-эффектом</h2>
        <div class="merch">
          <agile :options="merchOptions">
            <div v-for="item in carousel.merch" :key="item.key">
              <img :src="getPngImgUrl(item.image.src)" :alt="item.image.alt">
              <p>{{  item.image.alt }}</p>
              <div class="merch-price">{{ item.caption }}</div>
            </div>
          </agile>
        </div>
      </div>
      <div class="prizes-block-2">
        <h2>Копи баллы и участвуй в розыгрыше призов</h2>
        <img src="../assets/img/100000.png" alt="">
      </div>
      <div class="timer">
        <h2>до следующего ежемесячного розыгрыша осталось:</h2>
        <countdown :time="nextMonday">
          <template slot-scope="props">
            <div class="timer-block"><div class="timer-number">{{ props.days }}</div><br>дней</div>
            <div class="timer-block"><div class="timer-number">{{ props.hours }}</div><br>часов</div>
            <div class="timer-block"><div class="timer-number">{{ props.minutes }}</div><br>минут</div>
          </template>
        </countdown>
      </div>
    </section>
  </base-layout>
</template>
<script>

import BaseLayout from "@/layouts/BaseLayout"
import { VueAgile } from 'vue-agile'

import {
  descriptorOptions,
  certificatesOptions,
  prizesOptions,
  productsOptions,
  merchOptions,
  instrOptions,
  carousel
} from '@/carousel/config'

export default {
  name: "Prizes",
  components: {
    agile: VueAgile,
    BaseLayout
  },

  data() {
    const now = new Date()
    //const nextMonday = now.setDate(now.getDate() + (((1 + 31 - now.getDay()) % 7) || 7));
    const nextMonday = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    const now2 = new Date()

    return {
      descriptorOptions,
      certificatesOptions,
      prizesOptions,
      productsOptions,
      merchOptions,
      instrOptions,
      carousel,
      nextMonday: nextMonday - now2,
    }
  },

  methods: {
    getSvgImgUrl(image) {
      return require(`../assets/svg/${image}`)
    },

    getPngImgUrl(image) {
      return require(`../assets/img/${image}`)
    },

    showCurrentSlide(slide) {
      var list;
      document.querySelectorAll(".prizes-carousel").forEach((el) => {
        el.classList.remove('slide0');
        el.classList.remove('slide1');
        el.classList.remove('slide2');
        el.classList.remove('slide3');
        el.classList.remove('slide4');
        el.classList.add('slide' + slide.currentSlide);
      });

    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.prizes {
  text-align: center;
}

a {
  text-decoration: none;
}

h1, h2, h3 {
  text-align: center;
}

h1 {
  font-size: 60px;
  font-weight: 500;
  color: #3F3F3F;
}

.wrapper {
  background-color: #fff;
}

@media screen and (min-width: 993px) {
  .prizes {
    &::before, &::after {
      content: "";
      position: absolute;
      display: block;
      background-size: cover;
    }

    &::before {
      width: 90px;
      height: 100px;
      top: 120px;
      left: 10%;
      //background-image: url("../assets/svg/label-yellow-thunderbolt.svg");
    }

    &::after {
      width: 100px;
      height: 95px;
      top: 120px;
      right: 10%;
      //background-image: url("../assets/svg/label-heart.svg");
    }
  }
}

.prizes-block-1, .prizes-block-2, .prizes-block-3 {
  margin: 20px 8% 20px 8%;
  padding-top: 30px;
  background-size: contain;
  background-position: center;

  h2 {
    margin-top: 15px;
  }
}

.button {
  width: 400px;
  height: 60px;
  font-family: "Marvin Round";
  font-size: 20px;
  line-height: 60px;
  text-align: center;
  text-transform: uppercase;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin: 0 auto;
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
}

.button-main {
  background-color: $purple;
  color: $white;

  &:hover {
    background-color: $purple;
  }
}

.certificates-carousel {
  margin-top: 35px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: $white;
}

.prizes-block-1 {
  background-color: #F3F3F3;
  border-radius: 40px;
  padding-bottom: 25px;
}

.prizes-block-2 {
  background-color: #fff;
  border-radius: 40px;
  padding: 30px 40px 50px 40px;
  h2 {
    padding: 0 7.5% 0 7.5%;
    color: #3F3F3F;
  }
}

.prizes-block-3 {
  position: relative;
  background-color: #1EB1ED;
  border-radius: 40px;
  padding-bottom: 25px;
  z-index: 1;
  h2 {
    margin-top: 25px;
    color: $purple;
  }

  h3 {
    margin-bottom: 5px;
    font-size: 28px;
  }
}

.prizes-block-3:after {
  /*content: '';
  position: absolute;
  left: 25%;
  right: 0;
  top: 22%;
  bottom: 0;
  background-color: #fff;
  height: 70%;
  width: 50%;
  z-index: -1;
  border-radius: 40px;*/
}

.prizes-carousel {
  text-align: center;
}



.merch {
  margin-top: 45px;
  text-align: center;

  //& > div:nth-child(2) > p {
  //  margin-top: 70px;
  //}

  p {
    font-size: 20px;
    font-weight: 500;
  }
}

.merch-price {
  display: inline-block;
  height: 45px;
  padding: 0 20px 0 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 45px;
  color: $purple;
  background-color: #BABABA;
  border-radius: 50px;
  white-space: nowrap;
}

</style>

<style>
.agile__nav-button:hover {
  cursor: pointer;
}

.agile__dots {
  align-items: center;
  display: flex;
  list-style: none;
  padding: 0;
  white-space: nowrap;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
}

.what-to-do .agile__dots {
  bottom: -160px;
}

.products .agile__dots {
  bottom: -200px;
}

.prizes .agile__dots {
  bottom: -139px
}

.prizes-block-1 .agile__dots {
  bottom: -201px;
}

.prizes-block-3 .agile__dots {
  bottom: -155px;
}

.agile__dot {
  display: block;
  height: 10px;
  width: 10px;
  background-color: #4a4a4a;
  border-radius: 10px;
  transition: width 200ms;
  margin: 0 2.5px;
}

.agile__dot button {
  opacity: 0;
  height: 100%;
  width: 100%;
  color:transparent;
}

.agile__dot--current {
  width: 35px;
}

.descriptor-carousel .agile__nav-button {
  border: none;
  background-color: transparent;
  position: relative;
  height: 50px;
  width: 50px;
  color: transparent;
  margin: 0 5px 0 5px;
}

.descriptor-carousel .agile__nav-button:after {
  content: url('../assets/svg/arrow-yellow.svg');
  position:absolute;
  left: 0;
  top: 0;
}

.descriptor-carousel .agile__nav-button--next {
  transform: rotate(180deg);
}

.descriptor-carousel .agile__actions {
  justify-content: center;
}

.prizes-block-3 .agile__nav-button {
  border: none;
  background-color: transparent;
  position: relative;
  height: 50px;
  width: 50px;
  color: transparent;
  margin: 0 5px 0 5px;
}

.prizes-block-3 .agile__nav-button:after {
  content: url('../assets/svg/arrow-yellow.svg');
  position:absolute;
  left: 0;
  top: 0;
}

.prizes-block-3 .agile__nav-button--next {
  transform: rotate(180deg);
}

.prizes-block-3 .agile__actions {
  justify-content: space-between;
  position: absolute;
  width: 107%;
  bottom: 119px;
  left: -3.5%;
}

.products .agile__nav-button {
  border: none;
  background-color: transparent;
  position: relative;
  height: 50px;
  width: 50px;
  color: transparent;
  margin: 0 5px 0 5px;
}

.products .agile__nav-button:after {
  content: url('../assets/svg/arrow-white.svg');
  position:absolute;
  left: 0;
  top: 0;
}

.products .agile__nav-button--next {
  transform: rotate(180deg);
}

.products .agile__actions {
  justify-content: space-around;
  position: absolute;
  width: 100%;
  bottom: 175px;
}

@media screen and (max-width: 992px) {
  .descriptor-carousel .agile__actions {
    justify-content: space-between;
    position: absolute;
    bottom: 70px;
    left: 0;
    right: 0;
    width: 100%;
  }

  .what-to-do .agile__nav-button {
    border: none;
    background-color: transparent;
    position: relative;
    height: 50px;
    width: 50px;
    color: transparent;
    margin: 0 5px 0 5px;
  }

  .what-to-do .agile__nav-button:after {
    content: url('../assets/svg/arrow-yellow.svg');
    position:absolute;
    left: 0;
    top: 0;
  }

  .what-to-do .agile__nav-button--next {
    transform: rotate(180deg);
  }

  .what-to-do .agile__actions {
    justify-content: space-between;
    position: absolute;
    bottom: 50%;
    left: 0;
    right: 0;
    width: 100%;
  }

  .merch .agile__nav-button {
    border: none;
    background-color: transparent;
    position: relative;
    height: 50px;
    width: 50px;
    color: transparent;
    margin: 0 5px 0 5px;
  }

  .merch .agile__nav-button:after {
    content: url('../assets/svg/arrow-white.svg');
    position:absolute;
    left: 0;
    top: 0;
  }

  .merch .agile__nav-button--next {
    transform: rotate(180deg);
  }

  .merch .agile__actions {
    justify-content: space-between;
    position: absolute;
    bottom: 50%;
    left: 0;
    right: 0;
    width: 100%;
  }

  .certificates-carousel .agile__nav-button {
    border: none;
    background-color: transparent;
    position: relative;
    height: 50px;
    width: 50px;
    color: transparent;
    margin: 0 5px 0 5px;
  }

  .certificates-carousel .agile__nav-button:after {
    content: url('../assets/svg/arrow-yellow.svg');
    position:absolute;
    left: 0;
    top: 0;
  }

  .certificates-carousel .agile__nav-button--next {
    transform: rotate(180deg);
  }

  .certificates-carousel .agile__actions {
    justify-content: space-between;
    position: absolute;
    bottom: 50%;
    left: 0;
    right: 0;
    width: 100%;
  }

  .prizes-carousel .agile__actions {
    bottom: 50%;
  }

  .products .agile__actions {
    justify-content: space-between;
  }
}

.timer-block {
  color: #3F3F3F !important;
  width: 125px;
  height: 125px;
  padding-top: 25px;
  margin: 0 5px 0 5px;
  display: inline-block;
  border: 4px solid #BABABA;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 16px;
  text-align: center;
  font-family: "Marvin Round" !important;
}

.timer-number {
  font-size: 26px !important;
  display: block;
}

.timer {
  margin: 15px;
  text-align: center;
}

.timer h2 {
  color: #3F3F3F;
}

.prizes-carousel {
  text-align: center;
  margin-top: 5%;
  margin-bottom: 5%;
  width: 100%;
  margin-left: 2.5%;
}

@media screen and (max-width: 992px) {
  .prizes-carousel {
    text-align: center !important;
    margin-top: auto !important;
    width: auto !important;
    margin-left: auto !important;
    opacity: 1 !important;
  }

  .monthly-prizes {
    display: none;
  }
}

@media screen and (min-width: 993px) {
  .agile__slide .prizes-carousel-block {
    text-align: right;
  }

  .agile__slide .prizes-carousel-wrapper {
    text-align: center;
    float:right;
    width: 200px;
  }

  .agile__slide .prizes-carousel-block p {
    text-align: center;
  }

  .agile__slide.agile__slide--active .prizes-carousel-block {
    text-align: left;
  }

  .agile__slide.agile__slide--active .prizes-carousel-block p {
    text-align: center;
  }

  .agile__slide.agile__slide--active .prizes-carousel-block img {

  }

  .agile__slide.agile__slide--active .prizes-carousel-wrapper {
    width: 300px;
    text-align: center;
    float:left;
  }

  .agile__slide.agile__slide--active + .agile__slide + .agile__slide .prizes-carousel-wrapper {
    opacity: 0.5;
    float: none;
    margin: 0 auto;
  }

  .slide0 .agile__slide:nth-child(1) {
    opacity: 0.5;
  }

  .slide1 .agile__slide:nth-child(2) {
    opacity: 0.5;
  }

  .slide2 .agile__slide:nth-child(3) {
    opacity: 0.5;
  }

  .slide3 .agile__slide:nth-child(4) {
    opacity: 0.5;
  }

  .slide4 .agile__slide:nth-child(5) {
    opacity: 0.5;
  }

  .slide0 .agile__slide:nth-child(3) {
    opacity: 0.5;
  }

  .slide1 .agile__slide:nth-child(4) {
    opacity: 0.5;
  }

  .slide2 .agile__slide:nth-child(5) {
    opacity: 0.5;
  }

  .slide3 .agile__slide:nth-child(6) {
    opacity: 0.5;
  }

  .slide4 .agile__slide:nth-child(7) {
    opacity: 0.5;
  }

  .slide3 .agile__slides--cloned .agile__slide:nth-child(1) .prizes-carousel-wrapper {
    opacity: 0.5;
    float: none;
    margin: 0 auto;
  }

  .slide4 .agile__slides--cloned .agile__slide:nth-child(2) .prizes-carousel-wrapper {
    opacity: 0.5;
    float: none;
    margin: 0 auto;
  }

  .slide0 .agile__slide:nth-child(2) {
    opacity: 1;
  }

  .slide1 .agile__slide:nth-child(3) {
    opacity: 1;
  }

  .slide2 .agile__slide:nth-child(4) {
    opacity: 1;
  }

  .slide3 .agile__slide:nth-child(5) {
    opacity: 1;
  }

  .slide4 .agile__slide:nth-child(6) {
    opacity: 1;
  }
}

.monthly-prizes {
  position: absolute;
  background-color: #fff;
  border-radius: 40px;
  top: 19%;
  left: 50%;
  padding: 0px 45px;
  transform: translateX(-50%);
  text-align: center;
  width: 42%;

}

.monthly-prizes img {
  width: 100%;
}

.monthly-prizes-block {
  display: inline-block;
  width: 50%;
}

.monthly-prizes img {
  max-height: 200px;
  width: auto;
}

@media screen and (max-width: 1420px) {
  .prizes-carousel img {
    max-height: 145px;
  }

  .monthly-prizes img {
    max-height: 155px;
  }

  .prizes-carousel {
    margin-top: 7.5%
  }
}


.merch img {
  max-height: 200px;
}


.agile__slides {
  align-items: flex-start;
}

.agile__slides .merch {
  align-items: baseline;
}

img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}
</style>